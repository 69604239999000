import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { LocalStorageService } from './localstorage.service';
import { UtilitiesService } from './utilities.service';
import { map, catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { AuthService } from './auth.service';
import { EnvironmentService } from '../environment.service';
import { DatastorageService } from './datastorage.service';

export interface SearchParams {
  Filter?: string;
  page?: number; 
  pageSize?: number; 
  searchText?: string;
  sort?: string;
}

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  endpoints: any;

  constructor(
    private authSvc: AuthService,
    private http: HttpClient,
    private dsSvc: DatastorageService,
    private envSvc: EnvironmentService,
    private lsSvc: LocalStorageService,
    private utilsSvc: UtilitiesService
  ) {
    this.endpoints = this.envSvc.endpoints;
  }

  /**
   * Remove item from cache
   * @param cacheKey
   */
  private _removeCacheItem(cacheKey: string): void {

    const cache = this.dsSvc.getItem('cacheData');

    if (cache && cache['cacheData'] && cache['cacheData'][cacheKey]) {
      delete cache['cacheData'][cacheKey];
      this.dsSvc.setItem('cacheData', cache);
    }

  }

  /**
   * Get http headers
   * @param {string} contentType
   * @returns {object} httpHeaders
   */
  getHttpHeaders(responseType?: string, removeJsonContentType: boolean = false): object {

    // get the user
    const user = this.lsSvc.getItem('user');

    if (!user) {
     document.location.href = '/';
      return;
    }

    const headers = {
      'Accept': 'application/json',
      'Authorization': `Bearer ${user['token']}`
    };

    if (!removeJsonContentType) {
      headers['Content-Type'] = 'application/json';
    }

    if (responseType) {
      headers['ResponseType'] = responseType;
    }

    return {
      headers: new HttpHeaders(headers)
    };

  }

  /**
   * Handle error response
   * @param err
   */
  handleError(err: any) {

    if (err['status'] && err['status'] === 401) {
      this.lsSvc.setItem('sessionExpired', true);
      this.authSvc.logout(true);
      return throwError('Session expired');
    }

    // TODO: remove warning message
    // if (this.envSvc.isDev) {

    //   this.toastMsgSvc.send({
    //     icon: 'check_circle_outline',
    //     message: 'API request error occured',
    //     type: 'warning'
    //   });

    //   console.warn('error', err);

    // }

    return throwError(err);

  }

  /**
   * Search records
   * @param {object} params
   */
  public search(url: string, params: object, cacheKey?: string) {

    // if there is a cache key
    if (cacheKey) {

      // get the cached data
      const cache = this.dsSvc.getItem('cacheData');

      // check to see if the object exists
      if (cache && cache['cacheData'] && cache['cacheData'][cacheKey]) {

        // return observable with cached data
        return new Observable((observer) => {
          observer.next(cache['cacheData'][cacheKey]);
          observer.complete();
        });

      }

    }

    const httpHeaders = this.getHttpHeaders(),
      qParams = {
        searchText: params['searchText'] ? params['searchText'] : '',
        Filter: params['Filter'] ? params['Filter'] : '',
        sort: params['sort'] ? params['sort'] : '',
        page: params['page'] ? params['page'] : 1
      };

    if (params['pageSize']) {
      qParams['pageSize'] = params['pageSize'];
    }

    const query = this.utilsSvc.getQueryString(qParams);

    return this.http.get(`${url}${query}`, httpHeaders)
    .pipe(
        map((res) => {

          // if there is a cache key, cache the data
          if (cacheKey && res) {

            let cache = this.dsSvc.getItem('cacheData');

            if (!cache) {
              cache = {
                'cacheData': {}
              };
            }

            cache.cacheData[cacheKey] = res;

            this.dsSvc.setItem('cacheData', cache);

          }

          return res;

        }),
        catchError((err) => {
          return this.handleError(err);
        })
      );

  }

  /**
   * Get record
   * @param {string} url
   * @param {string} cacheKey - Optional string used to cache api response
   */
  public get(url: string, cacheKey?: string) {

    // if there is a cache key
    if (cacheKey) {

      // get the cached data
      const cache = this.dsSvc.getItem('cacheData');

      // check to see if the object exists
      if (cache && cache['cacheData'] && cache['cacheData'][cacheKey]) {

        // return observable with cached data
        return new Observable((observer) => {
          observer.next(cache['cacheData'][cacheKey]);
          observer.complete();
        });

      }

    }

    const httpHeaders = this.getHttpHeaders();

    return this.http.get(url, httpHeaders)
      .pipe(
        map((res) => {

          // if there is a cache key, cache the data
          if (cacheKey && res) {

            let cache = this.dsSvc.getItem('cacheData');

            if (!cache) {
              cache = {
                'cacheData': {}
              };
            }

            cache.cacheData[cacheKey] = res;

            this.dsSvc.setItem('cacheData', cache);

          }

          return res;

        }),
        catchError((err) => {
          return this.handleError(err);
        })
      );

  }

  /**
   * Add record
   * @param {string} url
   * @param {object} data
   */
  public add(url: string, data: object, cacheKey?: any) {

    const httpHeaders = this.getHttpHeaders();

    return this.http.post(url, data, httpHeaders)
      .pipe(
        map((res) => {

          if (cacheKey) {
            this._removeCacheItem(cacheKey);
          }

          return res;

        }),
        catchError((err) => {
          return this.handleError(err);
        })
      );

  }

  /**
   * Upload file
   * @param {string} url
   * @param {object} formData
   */
  public upload(url: string, formData: any) {

    const httpHeaders = this.getHttpHeaders(null, true);

    return this.http.post(url, formData, httpHeaders)
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((err) => {
          return this.handleError(err);
        })
      );

  }

  /**
   * Update record
   * @param {string} url
   * @param {object} data
   */
  public update(url: string, data: object, cacheKey?: any) {

    const httpHeaders = this.getHttpHeaders();

    return this.http.put(url, data, httpHeaders)
      .pipe(
        map((res) => {

          if (cacheKey) {
            this._removeCacheItem(cacheKey);
          }

          return res;

        }),
        catchError((err) => {
          return this.handleError(err);
        })
      );

  }

  /**
   * Update record
   * @param {string} url
   * @param {object} data
   */
  public updatePatch(url: string, data: object, cacheKey?: any) {

    const httpHeaders = this.getHttpHeaders();

    return this.http.patch(url, data, httpHeaders)
      .pipe(
        map((res) => {

          if (cacheKey) {
            this._removeCacheItem(cacheKey);
          }

          return res;

        }),
        catchError((err) => {
          return this.handleError(err);
        })
      );

  }

  /**
   * Get file
   * @param url
   * @param data
   * @param filename
   * @param method
   */
  public getFile(url: string, data: object, _filename: string = null, method: string = 'post') {

    // get the user
    const user = this.lsSvc.getItem('user');

    const httpMethod = method.toLowerCase();

    return this.http[httpMethod](url, data, {
        headers: new HttpHeaders({
          'Authorization': `Bearer ${user['token']}`,
        }),
        responseType: 'blob'
      })
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((err) => {
          return this.handleError(err);
        })
      );

  }

  /**
   * Delete record
   * @param {string} url
   */
  public delete(url: string) {

    // get the http headers
    const httpHeaders = this.getHttpHeaders();

    return this.http.delete(url, httpHeaders)
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((err) => {
          return this.handleError(err);
        })
      );

  }

}
