import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './services/auth-guard.service';
import { CanDeactivateGuard } from './services/can-deactivate-guard';

const routes: Routes = [{
  path: '',
  loadChildren: () => import('./login/login.module').then(m => m.LoginModule),
  data: { title: 'Login' }
}, {
  path: 'dashboard',
  loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
  data: { title: 'Dashboard' },
  canActivate: [AuthGuard]
}, {
  path: 'accounting',
  children: [
    {
      path: 'accounts-payable',
      children: [
        {
          path: 'invoices-charge-backs',
          loadChildren: () => import('./accounts-payable/invoices-chargebacks/invoices-chargebacks.module').then(m => m.InvoicesChargebacksModule)
        }, {
          path: 'payable-disbursements',
          loadChildren: () => import('./accounts-payable/payable-disbursements/payable-disbursements.module').then(m => m.PayableDisbursementsModule)
        }, {
          path: 'payments',
          loadChildren: () => import('./accounts-payable/ap-payments/ap-payments.module').then(m => m.ApPaymentsModule)
        }
      ]
    }, {
      path: 'banking',
      children: [
        {
          path: 'bank-register',
          loadChildren: () => import('./accounting/banking/bank-register/bank-register.module').then(m => m.BankRegisterModule)
        },
        {
          path: 'bank-transaction',
          loadChildren: () => import('./accounting/banking/bank-register/edit-bank-register/bank-register-registers/add-bank-register-transaction/add-bank-register-transaction.module').then(m => m.AddBankRegisterTransactionModule)
        }
      ]
    }, {
      path: 'general-ledger',
      children: [
        {
          path: 'chart-of-accounts',
          loadChildren: () => import('./accounting/general-ledger/chart-of-accounts/chart-of-accounts.module').then(m => m.ChartOfAccountsModule)
        }, {
          path: 'close-month',
          loadChildren: () => import('./accounting/general-ledger/close-month/close-month.module').then(m => m.CloseMonthModule)
        }, {
          path: 'end-of-year-processing',
          loadChildren: () => import('./accounting/general-ledger/end-of-year-processing/end-of-year-processing.module').then(m => m.EndOfYearProcessingModule)
        }, {
          path: 'financial-statements',
          loadChildren: () => import('./accounting/general-ledger/financial-statements/financial-statements.module').then(m => m.FinancialStatementsModule)
        }, {
          path: 'fiscal-years',
          loadChildren: () => import('./accounting/general-ledger/fiscal-years/fiscal-years.module').then(m => m.FiscalYearsModule)
        }, {
          path: 'journal-entry',
          loadChildren: () => import('./accounting/general-ledger/journal-entry/journal-entry.module').then(m => m.JournalEntryModule)
        }, {
          path: 'sales-gl-configuration',
          loadChildren: () => import('./accounting/general-ledger/sales-gl-configuration/sales-gl-configuration.module').then(m => m.SalesGlConfigurationModule)
        }, {
          path: 'sales-posting-update',
          loadChildren: () => import('./accounting/general-ledger/sales-posting-update/sales-posting-update.module').then(m => m.SalesPostingUpdateModule)
        }
      ]
    }, {
      path: 'reports',
      children: [
        {
          path: 'accounts-payable',
          loadChildren: () => import('./reports/accounts-payable-report/accounts-payable-report.module').then(m => m.AccountsPayableReportModule)
        }, {
          path: 'bank-account',
          loadChildren: () => import('./reports/bank-account-report/bank-account-report.module').then(m => m.BankAccountReportModule)
        }, {
          path: 'bank-reconciliation',
          loadChildren: () => import('./reports/bank-reconciliation-report/bank-reconciliation-report.module').then(m => m.BankReconciliationReportModule)
        }, {
          path: 'chart-of-accounts',
          loadChildren: () => import('./reports/chart-of-accounts-report/chart-of-accounts-report.module').then(m => m.ChartOfAccountsReportModule)
        }, {
          path: 'financial-statement-balance-sheet',
          loadChildren: () => import('./reports/financial-statement-balance-sheet/financial-statement-balance-sheet.module').then(m => m.FinancialStatementBalanceSheetModule)
        }, {
          path: 'financial-statement-income-statement',
          loadChildren: () => import('./reports/financial-statement-income-statement/financial-statement-income-statement.module').then(m => m.FinancialStatementIncomeStatementModule)
        }, {
          path: 'journal',
          loadChildren: () => import('./reports/journal-report/journal-report.module').then(m => m.JournalReportModule)
        }, {
          path: 'ledger',
          loadChildren: () => import('./reports/ledger-report/ledger-report.module').then(m => m.LedgerReportModule)
        }, {
          path: 'payables-disbursement',
          loadChildren: () => import('./reports/payables-disbursement-report/payables-disbursement-report.module').then(m => m.PayablesDisbursementReportModule)
        }, {
          path: 'purchase-journal',
          loadChildren: () => import('./reports/purchase-journal-report/purchase-journal-report.module').then(m => m.PurchaseJournalReportModule)
        }, {
          path: 'trial-balance',
          loadChildren: () => import('./reports/trial-balance-report/trial-balance-report.module').then(m => m.TrialBalanceReportModule)
        }
      ]
    }
  ]
}, {
  path: 'accounts',
  children: [
    {
      path: 'ar-transactions',
      loadChildren: () => import('./accounts-receivable/ar-transactions/ar-transactions.module').then(m => m.ArTransactionsModule)
    }, {
      path: 'reports',
      children: [{
        path: 'customer-account',
        loadChildren: () => import('./reports/customer-account-report/customer-account-report.module').then(m => m.CustomerAccountReportModule),
      }, {
        path: 'customer-statements',
        loadChildren: () => import('./reports/customer-statements/customer-statements.module').then(m => m.CustomerStatementsModule),
      }]
    }
  ]
}, {
  path: 'balancing',
  children: [
    {
      path: 'cash-tran-auto-deposit',
      loadChildren: () => import('./balancing/cash-tran-auto-deposit/cash-tran-auto-deposit.module').then(m => m.CashTranAutoDepositModule)
    },
    {
      path: 'close-day',
      loadChildren: () => import('./balancing/close-day/close-day.module').then(m => m.CloseDayModule)
    },
    {
      path: 'reports',
      children: [
        {
          path: 'ar-transaction',
          loadChildren: () => import('./reports/ar-transaction-report/ar-transaction-report.module').then(m => m.ARTransactionReportModule),
          data: { title: 'AR Transaction Report', claimProgram: 'ARTransactionReport', claimVerb: 'View' },
          canActivate: [AuthGuard]
        },
        {
          path: 'cash-transaction',
          loadChildren: () => import('./reports/cash-transaction-report/cash-transaction-report.module').then(m => m.CashTransactionReportModule),
          data: { title: 'Cash Transaction Report', claimProgram: 'CashTransactionReport', claimVerb: 'View' },
          canActivate: [AuthGuard]
        },
        {
          path: 'written-delivered-business',
          loadChildren: () => import('./reports/written-delivered-business-report/written-delivered-business-report.module').then(m => m.WrittenDeliveredBusinessReportModule),
          data: { title: 'Written Delivered Business Report', claimProgram: 'WrittenDeliveredBusinessReport', claimVerb: 'View' },
          canActivate: [AuthGuard]
        }
      ]
    }
  ]
}, {
  path: 'delivery',
  children: [
    {
      path: 'delivery-calendars',
      loadChildren: () => import('./delivery/delivery-calendars/delivery-calendars.module').then(m => m.DeliveryCalendarsModule)
    }, {
      path: 'delivery-zones',
      loadChildren: () => import('./delivery/delivery-zones/delivery-zones.module').then(m => m.DeliveryZonesModule)
    }, {
      path: 'scheduled-deliveries',
      loadChildren: () => import('./delivery/scheduled-deliveries/scheduled-deliveries.module').then(m => m.ScheduledDeliveriesModule)
    }, {
      path: 'trucks',
      loadChildren: () => import('./delivery/trucks/trucks.module').then(m => m.TrucksModule)
    }, {
      path: 'truck-routing',
      loadChildren: () => import('./delivery/truck-routing/truck-routing.module').then(m => m.TruckRoutingModule)
    }, {
      path: 'reports',
      children: [{
        path: 'delivery-calendar',
        loadChildren: () => import('./reports/delivery-calendar-report/delivery-calendar-report.module').then(m => m.DeliveryCalendarReportModule),
      },{
        path: 'dispatch-track-export',
        loadChildren: () => import('./reports/dispatch-track-export/dispatch-track-export.module').then(m => m.DispatchTrackExportModule),
      }, {
        path: 'truck-stop',
        loadChildren: () => import('./reports/truck-stop-report/truck-stop-report.module').then(m => m.TruckStopReportModule),
      }]
    }
  ]
},
{
  path: 'merchandising',
  children: [
    {
      path: 'custom-items',
      children: [
        {
          path: '',
          loadChildren: () => import('./merchandising/custom-items/custom-items.module').then(m => m.CustomItemsModule),
          data: { title: 'Custom Items', claimProgram: 'CustomItems', claimVerb: 'View' },
          canActivate: [AuthGuard]
        }, {
          path: 'add',
          loadChildren: () => import('./merchandising/custom-items/add-custom-item/add-custom-item.module').then(m => m.AddCustomItemModule),
          data: { title: 'Add Custom Item', claimProgram: 'CustomItems', claimVerb: 'Add' },
          canActivate: [AuthGuard]
        }, {
          path: ':id',
          loadChildren: () => import('./merchandising/custom-items/edit-custom-item/edit-custom-item.module').then(m => m.EditCustomItemModule),
          data: { title: 'Edit Custom Item Group', claimProgram: 'CustomItems', claimVerb: ['Edit', 'View'] },
          canActivate: [AuthGuard]
        }, {
          path: ':id/:tab',
          loadChildren: () => import('./merchandising/custom-items/edit-custom-item/edit-custom-item.module').then(m => m.EditCustomItemModule),
          data: { claimProgram: 'SalesOrders', claimVerb: 'View' },
          canActivate: [AuthGuard]
        }
      ]
    }, {
      path: 'departments',
      children: [
        {
          path: '',
          loadChildren: () => import('./merchandising/departments/departments.module').then(m => m.DepartmentsModule),
          data: { title: 'Departments', claimProgram: 'Departments', claimVerb: 'View' },
          canActivate: [AuthGuard]
        }, {
          path: 'add',
          loadChildren: () => import('./merchandising/departments/add/add-department.module').then(m => m.AddDepartmentModule),
          data: { title: 'Add Department', claimProgram: 'Departments', claimVerb: 'Add' },
          canActivate: [AuthGuard]
        }, {
          path: ':id',
          loadChildren: () => import('./merchandising/departments/edit-department/edit-department.module').then(m => m.EditDepartmentModule),
          data: { title: 'Edit Department', claimProgram: 'Departments', claimVerb: ['Edit', 'View'] },
          canActivate: [AuthGuard]
        }, {
          path: ':id/:tab',
          loadChildren: () => import('./merchandising/departments/edit-department/edit-department.module').then(m => m.EditDepartmentModule),
          data: { claimProgram: 'Departments', claimVerb: ['Edit', 'View'] },
          canActivate: [AuthGuard]
        },
      ],
    }, {
      path: 'groupmodels',
      children: [
        {
          path: '',
          loadChildren: () => import('./merchandising/group-models/group-models.module').then(m => m.GroupModelsModule),
          data: { title: 'GroupModels', claimProgram: 'GroupModels', claimVerb: 'View' },
          canActivate: [AuthGuard]
        }, {
          path: 'add',
          loadChildren: () => import('./merchandising/group-models/add-group-model/add-group-model.module').then(m => m.AddGroupModelModule),
          data: { title: 'Add Group Model', claimProgram: 'GroupModels', claimVerb: 'Add' }
        }, {
          path: ':id',
          loadChildren: () => import('./merchandising/group-models/edit-group-model/edit-group-model.module').then(m => m.EditGroupModelModule),
          data: { title: 'Edit Group Model', claimProgram: 'GroupModels', claimVerb: ['Edit', 'View'] },
          canActivate: [AuthGuard]
        }, {
          path: ':id/:tab',
          loadChildren: () => import('./merchandising/group-models/edit-group-model/edit-group-model.module').then(m => m.EditGroupModelModule),
          data: { claimProgram: 'GroupModels', claimVerb: ['Edit', 'View'] },
          canActivate: [AuthGuard]
        }
      ],
    }, {
      path: 'models',
      children: [
        {
          path: '',
          loadChildren: () => import('./merchandising/models/models.module').then(m => m.ModelsModule),
          data: { title: 'Models', claimProgram: 'Models', claimVerb: 'View' },
          canActivate: [AuthGuard]
        }, {
          path: 'add',
          loadChildren: () => import('./merchandising/models/add-model/add-model.module').then(m => m.AddModelModule),
          data: { title: 'Add Model', claimProgram: 'Models', claimVerb: 'Add' },
          canActivate: [AuthGuard]
        }, {
          path: ':id',
          loadChildren: () => import('./merchandising/models/edit-model/edit-model.module').then(m => m.EditModelModule),
          data: { title: 'Edit Model', claimProgram: 'Models', claimVerb: ['Edit', 'View'] },
          canActivate: [AuthGuard]
        }, {
          path: ':id/:tab',
          loadChildren: () => import('./merchandising/models/edit-model/edit-model.module').then(m => m.EditModelModule),
          data: { claimProgram: 'Models', claimVerb: ['Edit', 'View'] },
          canActivate: [AuthGuard]
        }
      ]
    }, {
      path: 'model-bulk-update',
      loadChildren: () => import('./merchandising/model-bulk-update/model-bulk-update.module').then(m => m.ModelBulkUpdateModule)
    }
    , {
      path: 'catalog-import',
      children: [
        {
          path: '',
          loadChildren: () => import('./merchandising/catalog-import/catalog-import.module').then(m => m.CatalogImportModule),
          data: { title: 'Catalog Import', claimProgram: 'CatalogImport', claimVerb: 'View' },
          canActivate: [AuthGuard]
        },
         {
          path: ':tab',
          loadChildren: () => import('./merchandising/catalog-import/catalog-import.module').then(m => m.CatalogImportModule),
          data: { claimProgram: 'GroupModels', claimVerb: ['Edit', 'View'] },
          canActivate: [AuthGuard]
        }
      ]     
    }
    ,{
      path: 'purchase-orders',
      children: [
        {
          path: '',
          loadChildren: () => import('./merchandising/purchase-orders/purchase-orders.module').then(m => m.PurchaseOrdersModule),
        }
      ]
    },  {
      path: 'purchase-orders-min-max',
      children: [
        {
          path: '',
          loadChildren: () => import('./merchandising/purchase-orders-min-max/purchase-orders-min-max.module').then(m => m.PurchaseOrdersMinMaxModule),
          data: { title: 'Purchase Order Min Max', claimProgram: 'PoMinMax', claimVerb: 'View' },
          canActivate: [AuthGuard]
        }
      ]
    }, {
      path: 'customer-back-orders',
      children: [
        {
          path: '',
          loadChildren: () => import('./merchandising/po-customer-back-orders/po-customer-back-orders.module').then(m => m.PoCustomerBackOrdersModule),
        }
      ]
    }, {
      path: 'vendors',
      children: [
        {
          path: '',
          loadChildren: () => import('./merchandising/vendors/vendors.module').then(m => m.VendorsModule),
          data: { title: 'Vendors', claimProgram: 'Vendors', claimVerb: 'View' },
          canActivate: [AuthGuard]
        }, {
          path: 'add',
          loadChildren: () => import('./merchandising/vendors/add-vendor/add-vendor.module').then(m => m.AddVendorModule),
          data: { title: 'Add Vendor', claimProgram: 'Vendors', claimVerb: 'Add' },
          canActivate: [AuthGuard]
        }, {
          path: ':id',
          loadChildren: () => import('./merchandising/vendors/edit-vendor/edit-vendor.module').then(m => m.EditVendorModule),
          data: { title: 'Edit Vendor', claimProgram: 'Vendors', claimVerb: ['Edit', 'View'] },
          canActivate: [AuthGuard]
        }, {
          path: ':id/:tab',
          loadChildren: () => import('./merchandising/vendors/edit-vendor/edit-vendor.module').then(m => m.EditVendorModule),
          data: { claimProgram: 'Vendors', claimVerb: ['Edit', 'View'] },
          canActivate: [AuthGuard]
        }
      ]
    }, {
      path: 'reports',
      children: [
        {
          path: 'group-model',
          loadChildren: () => import('./reports/group-model-report/group-model-report.module').then(m => m.GroupModelReportModule),
          data: { title: 'Group Model Report', claimProgram: 'GroupModelReport', claimVerb: 'View' },
          canActivate: [AuthGuard]
        }, {
          path: 'inventory-allocation',
          loadChildren: () => import('./reports/inventory-allocation-report/inventory-allocation-report.module').then(m => m.InventoryAllocationReportModule),
          data: { title: 'Inventory Allocation Report', claimProgram: 'MerchandiseManagersReport', claimVerb: 'View' },
          canActivate: [AuthGuard]
        }, {
          path: 'merchandise-managers',
          loadChildren: () => import('./reports/merchandise-managers-report/merchandise-managers-report.module').then(m => m.MerchandiseManagersReportModule),
          data: { title: 'Merchandise Managers Report', claimProgram: 'MerchandiseManagersReport', claimVerb: 'View' },
          canActivate: [AuthGuard]
        }, {
          path: 'merchandise-managers-unsampled',
          loadChildren: () => import('./reports/merchandise-managers-unsampled-report/merchandise-managers-unsampled-report.module').then(m => m.MerchandiseManagersUnsampledReportModule),
          data: { title: 'Merchandise Managers Unsampled Report', claimProgram: 'MerchandiseManagersReport', claimVerb: 'View' },
          canActivate: [AuthGuard]
        }, {
          path: 'merchandise-managers-best-sellers',
          loadChildren: () => import('./reports/merchandise-managers-best-sellers-report/merchandise-managers-best-sellers-report.module').then(m => m.MerchandiseManagersBestSellersReportModule),
          data: { title: 'Merchandise Managers Best Sellers Report', claimProgram: 'MerchandiseManagersReport', claimVerb: 'View' },
          canActivate: [AuthGuard]
        }, {
          path: 'purchase-order',
          loadChildren: () => import('./reports/purchase-order-report/purchase-order-report.module').then(m => m.PurchaseOrderReportModule),
          data: { title: 'Purchase Order Report', claimProgram: 'PurchaseOrderReport', claimVerb: 'View' },
          canActivate: [AuthGuard]
        }
      ]
    }
  ]
}, {
  path: 'cart',
  loadChildren: () => import('./cart/cart.module').then(m => m.CartModule),
  data: { title: 'Cart', claimProgram: 'Carts', claimVerb: 'View' },
  canActivate: [AuthGuard]
}, {
  path: 'carts',
  loadChildren: () => import('./carts/carts.module').then(m => m.CartsModule),
  data: { title: 'Carts', claimProgram: 'Carts', claimVerb: 'View' },
  canActivate: [AuthGuard]
}, {
  path: 'inventory',
  children: [
    {
    path: 'adjustments',
    children: [
      {
        path: '',
        loadChildren: () => import('./inventory/inventory-adjustments/inventory-adjustments.module').then(m => m.InventoryAdjustmentsModule),
        data: {title: 'Inventory Adjustments', claimProgram: 'InvAddSubtractAdjustments', claimVerb: 'View'},
        canActivate: [AuthGuard]
      },
      {
        path: 'bulk',
        loadChildren: () => import('./inventory/inventory-adjustments/bulk-inventory-add/bulk-inventory-add.module').then(m => m.BulkInventoryAddModule),
        data: {title: 'Inventory Bulk Quantities', claimProgram: 'InvAddSubtractAdjustments', claimVerb: 'Bulk Inventory Add'},
        canActivate: [AuthGuard]
      }
    ]
  }, {
    path: 'cost-freight-adjustments',
    loadChildren: () => import('./inventory/inventory-cost-freight-adjustments/inventory-cost-freight-adjustments.module').then(m => m.InventoryCostFreightAdjustmentsModule),
  }, {
    path: 'cycle-inventory',
    loadChildren: () => import('./inventory/cycle-inventory/cycle-inventory.module').then(m => m.CycleInventoryModule),
    data: {title: 'Cycle Inventory', claimProgram: 'CycleInventory', claimVerb: 'View'},
    canActivate: [AuthGuard]
  }, {
    path: 'cycle-inventory-mobile',
    loadChildren: () => import('./inventory/cycle-inventory-mobile/cycle-inventory-mobile.module').then(m => m.CycleInventoryMobileModule),
    data: {title: 'BarcodeCycle Inventory', claimProgram: 'BarcodeCycleInventory', claimVerb: 'View'},
    canActivate: [AuthGuard]
  }, {
  path: 'inventory-allocation',
  children: [{
    path: '',
    loadChildren: () => import('./inventory/inventory-allocation/inventory-allocation.module').then(m => m.InventoryAllocationModule),
    data: { title: 'Inventory Allocation', claimProgram: 'InventoryAllocation', claimVerb: 'View' },
    canActivate: [AuthGuard]
  }]
  }, {
    path: 'receivings',
    children: [
      {
        path: '',
        loadChildren: () => import('./inventory/inventory-receiving/inventory-receiving.module').then(m => m.InventoryReceivingModule),
        data: { title: 'Inventory Receiving', claimProgram: 'InventoryReceivings', claimVerb: 'View' },
        canActivate: [AuthGuard]
      }, {
        path: 'add',
        loadChildren: () => import('./inventory/inventory-receiving/inventory-receiving-edit/inventory-receiving-edit.module').then(m => m.InventoryReceivingEditModule),
        data: { title: 'Add Inventory Receiving', claimProgram: 'InventoryReceivings', claimVerb: 'Add' },
        canActivate: [AuthGuard]
      }, {
        path: ':id',
        loadChildren: () => import('./inventory/inventory-receiving/inventory-receiving-view/inventory-receiving-view.module').then(m => m.InventoryReceivingViewModule),
        data: { title: 'Inventory Receving View' },
        canActivate: [AuthGuard]
      }, {
        path: ':id/print',
        loadChildren: () => import('./inventory/inventory-receiving/inventory-receiving-line-items/inventory-receiving-line-items-print/inventory-receiving-line-items-print.module').then(m => m.InventoryReceivingLineItemsPrintModule),
        data: { title: 'Inventory Receving Print Line Items' },
        canActivate: [AuthGuard]
      }
    ]
  }, 
  {
    path: 'receivings-mobile',
    children: [      
      {
        path: 'receive-into-one-location/:id',
        loadChildren: () => import('./inventory/inventory-receiving-mobile/receive-into-one-location/receive-into-one-location.module').then(m => m.ReceiveIntoOneLocationModule),
        data: { title: 'Receive into 1 Location', claimProgram: 'BarcodeReceiving', claimVerb: 'View' },
        canActivate: [AuthGuard]
      },
      {
        path: 'receive-into-one-location/:id',
        loadChildren: () => import('./inventory/inventory-receiving-mobile/receive-into-one-location/receive-into-one-location.module').then(m => m.ReceiveIntoOneLocationModule),
        data: { title: 'Receive into Multi Location', claimProgram: 'BarcodeReceiving', claimVerb: 'View' },
        canActivate: [AuthGuard]
     
      }
    ]
  },
  {
    path: 'lookup-mobile',
    loadChildren: () => import('./inventory/inventory-lookup-mobile/inventory-lookup-mobile.module').then(m => m.InventoryLookupMobileModule),
    data: { title: 'Barcode Inventory Lookup', claimProgram: 'BarcodeInventoryLookup', claimVerb: 'View' },
    canActivate: [AuthGuard]
  },
  {
    path: 'lookup',
    children: [{
      path: '',
      loadChildren: () => import('./inventory/inventory-lookup/inventory-lookup.module').then(m => m.InventoryLookupModule),
      data: { title: 'Inventory Lookup' },
      canActivate: [AuthGuard]
    }, {
      path: ':productModelId',
      loadChildren: () => import('./inventory/inventory-details-tabs/inventory-details-tabs.module').then(m => m.InventoryDetailsTabsModule),
      data: { title: 'Inventory Item Details' },
      canActivate: [AuthGuard]
    }, {
      path: ':productModelId/:tab',
      loadChildren: () => import('./inventory/inventory-quantities/inventory-quantities.module').then(m => m.InventoryQuantitiesModule),
      data: { title: 'Inventory' },
      canActivate: [AuthGuard]
    }, {
      path: ':productModelId/customize/:customItemHeaderId',
      loadChildren: () => import('./inventory/inventory-customize/inventory-customize.module').then(m => m.InventoryCustomizeModule),
      data: { title: 'Inventory Lookup Customize' },
      canActivate: [AuthGuard]
    }, {
      path: ':productModelId/customize/:customItemHeaderId/:vendorCode',
      loadChildren: () => import('./inventory/inventory-customize/inventory-customize.module').then(m => m.InventoryCustomizeModule),
      data: { title: 'Inventory Lookup Customize' },
      canActivate: [AuthGuard]
    }]
  }, {
    path: 'labels-tags/on-hand-quantities',
    loadChildren: () => import('./reports/inventory-labels-tags-on-hand/inventory-labels-tags-on-hand.module').then(m => m.InventoryLabelsTagsOnHandModule),
    data: {title: 'Inventory Labels/Tags On Hand', claimProgram: 'InventoryLabelsTags', claimVerb: 'View'},
    canActivate: [AuthGuard]
  }, {
    path: 'labels-tags/group-models',
    loadChildren: () => import('./reports/inventory-labels-tags-group-model/inventory-labels-tags-group-model.module').then(m => m.InventoryLabelsTagsGroupModelModule),
    data: {title: 'Inventory Labels/Tags Group Models', claimProgram: 'GroupModelTags', claimVerb: 'View'},
    canActivate: [AuthGuard]
  }, {
    path: 'labels-tags/frame-models',
    loadChildren: () => import('./reports/inventory-labels-tags-frames/inventory-labels-tags-frames.module').then(m => m.InventoryLabelsTagsFramesModule),
    data: {title: 'Inventory Frame Models'},
    canActivate: [AuthGuard]
  }, {
    path: 'labels-tags/locations',
    loadChildren: () => import('./reports/inventory-labels-tags-locations/inventory-labels-tags-locations.module').then(m => m.InventoryLabelsTagsLocationsModule),
    data: {title: 'Inventory Locations'},
    canActivate: [AuthGuard]
  }, {
    path: 'reports',
    children: [
      {
        path: 'inventory-cost',
        loadChildren: () => import('./reports/inventory-cost-report/inventory-cost-report.module').then(m => m.InventoryCostReportModule),
        data: { title: 'Inventory Cost Report', claimProgram: 'InventoryCostReport', claimVerb: 'View' },
        canActivate: [AuthGuard]
      }, {
        path: 'inventory-picking',
        loadChildren: () => import('./reports/inventory-picking-report/inventory-picking-report.module').then(m => m.InventoryPickingReportModule),
        data: { title: 'Inventory Picking Report', claimProgram: 'InventoryPickingReport', claimVerb: 'View' },
        canActivate: [AuthGuard]
      }, {
        path: 'inventory-receiving',
        loadChildren: () => import('./reports/inventory-receiving-report/inventory-receiving-report.module').then(m => m.InventoryReceivingReportModule),
        data: { title: 'Inventory Receiving Report', claimProgram: 'InventoryReceivingReport', claimVerb: 'View' },
        canActivate: [AuthGuard]
      }, {
        path: 'inventory-receiving-worksheet',
        loadChildren: () => import('./reports/inventory-receiving-worksheet/inventory-receiving-worksheet.module').then(m => m.InventoryReceivingWorksheetModule),
        data: { title: 'Inventory Receiving Worksheet', claimProgram: 'InventoryReceivingWorksheet', claimVerb: 'View' },
        canActivate: [AuthGuard]
      }, {
        path: 'inventory-status',
        loadChildren: () => import('./reports/inventory-status-report/inventory-status-report.module').then(m => m.InventoryStatusReportModule),
      data: { title: 'Inventory Status Report', claimProgram: 'InventoryStatusReport', claimVerb: 'View' },
        canActivate: [AuthGuard]
      }, {
        path: 'inventory-transaction',
        loadChildren: () => import('./reports/inventory-transaction-report/inventory-transaction-report.module').then(m => m.InventoryTransactionReportModule),
        data: { title: 'Inventory Transaction Report', claimProgram: 'InventoryTransactionReport', claimVerb: 'View' },
        canActivate: [AuthGuard]
      }, {
        path: 'inventory-transfer',
        loadChildren: () => import('./reports/inventory-transfer-report/inventory-transfer-report.module').then(m => m.InventoryTransferReportModule),
        data: { title: 'Inventory Transfer Report', claimProgram: 'InventoryTransferReport', claimVerb: 'View' },
        canActivate: [AuthGuard]
      }, {
        path: 'physical-inventory',
        loadChildren: () => import('./reports/physical-inventory-report/physical-inventory-report.module').then(m => m.PhysicalInventoryReportModule),
        data: { title: 'Physical Inventory Report', claimProgram: 'PhysicalInventoryReport', claimVerb: 'View' },
        canActivate: [AuthGuard]
      }
    ]
  }, {
    path: 'transfers',
    children: [
      {
        path: '',
        loadChildren: () => import('./inventory/inventory-transfers/inventory-transfers.module').then(m => m.InventoryTransfersModule),
        data: { title: 'Inventory Transfers', programName: 'InventoryTransfers', verb: 'View' },
        canActivate: [AuthGuard]
      }
    ]
  },
  {
    path: 'transfers-mobile',
    loadChildren: () => import('./inventory/inventory-transfers-mobile/inventory-transfers-mobile.module').then(m => m.InventoryTransfersMobileModule),
    data: { title: 'Barcode Transfers', claimProgram: 'BarcodeTransfer', claimVerb: 'View' },
    canActivate: [AuthGuard]
  },
  ]
}, {
  path: 'sales',
  children: [{
    path: 'accounts',
    children: [
      {
        path: '',
        loadChildren: () => import('./sales/sales-accounts/sales-accounts.module').then(m => m.SalesAccountsModule),
        data: { title: 'Sales Accounts', claimprogram: 'CustomerAccounts', claimVerb: 'View' },
        canActivate: [AuthGuard]
      }, {
        path: 'add',
        loadChildren: () => import('./sales/sales-accounts/edit-sales-account/edit-sales-account.module').then(m => m.EditSalesAccountModule),
        data: { title: 'Add Sales Account', claimprogram: 'CustomerAccounts', claimVerb: 'Add' },
        canActivate: [AuthGuard]
      }, {
        path: ':id',
        loadChildren: () => import('./sales/sales-accounts/edit-sales-account/edit-sales-account.module').then(m => m.EditSalesAccountModule),
        data: { title: 'Edit Sales Account', claimprogram: 'CustomerAccounts', claimVerb: ['Edit', 'View'] },
        canActivate: [AuthGuard]
      }
    ]
  },  {
    path: 'commission-adjustments',
    loadChildren: () => import('./sales/commission-adjustments/commission-adjustments.module').then(m => m.CommissionAdjustmentsModule),
    data: {title: 'Commission Adjustments', claimProgram: 'CommissionAdjustments', claimVerb: 'View'},
    canActivate: [AuthGuard]
  }, {
    path: 'adjustments',
    loadChildren: () => import('./sales/sales-adjustments/sales-adjustments.module').then(m => m.SalesAdjustmentsModule),
    data: {title: 'Sales Adjustments', claimProgram: 'SalesAdjustments', claimVerb: 'View'},
    canActivate: [AuthGuard]
  }, {
    path: 'customer-accounts',
    loadChildren: () => import('./sales/customer-accounts/customer-accounts.module').then(m => m.CustomerAccountsModule),
    data: { title: 'Customer Accounts', claimprogram: 'CustomerAccounts', claimVerb: 'View' },
    canActivate: [AuthGuard]
  }, {
    path: 'orders',
    children: [
      {
        path: '',
        loadChildren: () => import('./sales/sales-orders/sales-orders.module').then(m => m.SalesOrdersModule)
      }
    ]
  }, {
    path: 'service-orders',
    loadChildren: () => import('./sales/service-orders/service-orders.module').then(m => m.ServiceOrdersModule)
  }, {
    path: 'reports',
    children: [
      {
        path: 'commission',
        loadChildren: () => import('./reports/commission-report/commission-report.module').then(m => m.CommissionReportModule),
        data: { title: 'Commission Report', claimProgram: 'CommissionReport', claimVerb: 'View' },
        canActivate: [AuthGuard]
      },
      {
        path: 'demographics',
        loadChildren: () => import('./reports/demographics-report/demographics-report.module').then(m => m.DemographicsReportModule),
        data: { title: 'Demographics Report', claimProgram: 'DemographicsAnalysisReport', claimVerb: 'View' },
        canActivate: [AuthGuard]
      }, {
        path: 'open-sales',
        loadChildren: () => import('./reports/open-sales-report/open-sales-report.module').then(m => m.OpenSalesReportModule),
        data: {title: 'Open Sales Report', claimProgram: 'OpenSalesReportGet', claimVerb: 'View'},
        canActivate: [AuthGuard]
      }, {
        path: 'sales-analysis',
        loadChildren: () => import('./reports/sales-analysis-report/sales-analysis-report.module').then(m => m.SalesAnalysisReportModule),
        data: { title: 'Sales Analysis Report', claimProgram: 'SalesAnalysisReport', claimVerb: 'View' },
        canActivate: [AuthGuard]
      }, {
        path: 'sales-order',
        loadChildren: () => import('./reports/sales-order-report/sales-order-report.module').then(m => m.SalesOrderReportModule),
        data: { title: 'Sales Order Report', claimProgram: 'SalesOrderReport', claimVerb: 'View' },
        canActivate: [AuthGuard]
      }, {
        path: 'sales-tax',
        loadChildren: () => import('./reports/sales-tax-report/sales-tax-report.module').then(m => m.SalesTaxReportModule),
        data: { title: 'Sales Tax Report', claimProgram: 'SalesTaxReport', claimVerb: 'View' },
        canActivate: [AuthGuard]
      }, {
        path: 'service-order',
        loadChildren: () => import('./reports/service-order-report/service-order-report.module').then(m => m.ServiceOrderReportModule),
        data: { title: 'Service Order Report', claimProgram: 'ServiceOrderReportGet', claimVerb: 'View' },
        canActivate: [AuthGuard]
      }
    ]
  }, {
    path: 'returns',
    loadChildren: () => import('./sales/returns/returns.module').then(m => m.ReturnsModule)
  }]
}, {
  path: 'configuration',
  children: [{
    path: 'codes',
    children: [
      {
        path: 'inventory',
        children: [ 
          {
            path: 'adjustments',
            children: [{
              path: '',
              loadChildren: () => import('./configuration/codes/inventory/inventory-adjustments/inventory-adjustments.module').then(m => m.InventoryAdjustmentsModule),
              data: { title: 'Inventory Adjustments', claimProgram: 'CodesInventoryAdjustments', claimVerb: 'View' },
              canActivate: [AuthGuard]
            }, {
              path: 'add',
              loadChildren: () => import('./configuration/codes/inventory/inventory-adjustments/inventory-adjustments-edit/inventory-adjustments-edit.module').then(m => m.InventoryAdjustmentsEditModule),
              data: { title: 'Add Inventory Adjustment', claimProgram: 'CodesInventoryAdjustments', claimVerb: 'Add' },
              canActivate: [AuthGuard]
            }, {
              path: ':id',
              loadChildren: () => import('./configuration/codes/inventory/inventory-adjustments/inventory-adjustments-edit/inventory-adjustments-edit.module').then(m => m.InventoryAdjustmentsEditModule),
              data: { title: 'Edit Inventory Adjustment', claimProgram: 'CodesInventoryAdjustments', claimVerb: 'Edit' },
              canActivate: [AuthGuard]
            }]
          }, {
            path: 'status',
            children: [
              {
                path: '',
                loadChildren: () => import('./configuration/codes/inventory/inventory-status/inventory-status.module').then(m => m.InventoryStatusModule),
                data: { title: 'Inventory Status Codes', claimProgram: 'CodesInventoryStatus', claimVerb: 'View' },
                canActivate: [AuthGuard]
              }, {
                path: 'add',
                loadChildren: () => import('./configuration/codes/inventory/inventory-status/inventory-status-edit/inventory-status-edit.module').then(m => m.InventoryStatusEditModule),
                data: { title: 'Add Inventory Status Codes', claimProgram: 'CodesInventoryStatus', claimVerb: 'Add' },
                canActivate: [AuthGuard]
              }, {
                path: ':id',
                loadChildren: () => import('./configuration/codes/inventory/inventory-status/inventory-status-edit/inventory-status-edit.module').then(m => m.InventoryStatusEditModule),
                data: { title: 'Edit Inventory Status Codes', claimProgram: 'CodesInventoryStatus', claimVerb: 'Edit' },
                canActivate: [AuthGuard]
              }
            ]
          }, {
            path: 'transfer',
            children: [
              {
                path: '',
                loadChildren: () => import('./configuration/codes/inventory/inventory-transfer/inventory-transfer.module').then(m => m.InventoryTransferModule),
                data: { title: 'Inventory Transfer Codes', claimProgram: 'CodesInventoryTransfer', claimVerb: 'View' },
                canActivate: [AuthGuard]
              }, {
                path: 'add',
                loadChildren: () => import('./configuration/codes/inventory/inventory-transfer/inventory-transfer-edit/inventory-transfer-edit.module').then(m => m.InventoryTransferEditModule),
                data: { title: 'Add Inventory Transfer Codes', claimProgram: 'CodesInventoryTransfer', claimVerb: 'Add' },
                canActivate: [AuthGuard]
              }, {
                path: ':id',
                loadChildren: () => import('./configuration/codes/inventory/inventory-transfer/inventory-transfer-edit/inventory-transfer-edit.module').then(m => m.InventoryTransferEditModule),
                data: { title: 'Edit Inventory Transfer Codes', claimProgram: 'CodesInventoryTransfer', claimVerb: 'Edit' },
                canActivate: [AuthGuard]
              }
            ]
          }]
      }, {
        path: 'advertising',
        children: [
          {
            path: '',
            loadChildren: () => import('./configuration/codes/advertising/advertising.module').then(m => m.AdvertisingModule),
          }
        ]
      }, {
        path: 'customer-follow-up',
        children: [
          {
            path: '',
            loadChildren: () => import('./configuration/codes/customer-follow-up/customer-follow-up.module').then(m => m.CustomerFollowUpModule),
          }
        ]
      }, {
        path: 'method-of-payment',
        children: [
          {
            path: '',
            loadChildren: () => import('./configuration/codes/method-of-payment/method-of-payment.module').then(m => m.MethodOfPaymentModule),
            data: { title: 'Method of Payment', claimProgram: 'CodesMethodOfPayment', claimVerb: 'View' },
            canActivate: [AuthGuard]
          }, {
            path: 'add',
            loadChildren: () => import('./configuration/codes/method-of-payment/edit-method-of-payment/edit-method-of-payment.module').then(m => m.EditMethodOfPaymentModule),
            data: { title: 'Add Method of Payment', claimProgram: 'CodesMethodOfPayment', claimVerb: 'Add' },
            canActivate: [AuthGuard]
          }, {
            path: ':id',
            loadChildren: () => import('./configuration/codes/method-of-payment/edit-method-of-payment/edit-method-of-payment.module').then(m => m.EditMethodOfPaymentModule),
            data: { title: 'Edit Method of Payment', claimProgram: 'CodesMethodOfPayment', claimVerb: ['Edit', 'View'] },
            canActivate: [AuthGuard]
          }, {
            path: ':id/general-ledger',
            loadChildren: () => import('./configuration/codes/method-of-payment/edit-method-of-payment/method-of-payment-deposit-bank-gl/method-of-payment-deposit-bank-gl.module').then(m => m.MethodOfPaymentDepositBankGlModule),
            data: { title: 'Edit Additional Service G/L', claimProgram: 'CodesAdditionalServices', claimVerb: 'Edit' },
            canActivate: [AuthGuard]
          }
        ]
      }, {
        path: 'additional-services',
        children: [
          {
            path: '',
            loadChildren: () => import('./configuration/codes/additional-services/additional-services.module').then(m => m.AdditionalServicesModule)
          }
        ]
      }, {
        path: 'ar-finance',
        children: [
          {
            path: '',
            loadChildren: () => import('./configuration/codes/ar-finance-codes/ar-finance-codes.module').then(m => m.ArFinanceCodesModule)
          }
        ]
      }, {
        path: 'ar-transactions',
        children: [
          {
            path: '',
            loadChildren: () => import('./configuration/codes/ar-transaction-codes/ar-transaction-codes.module').then(m => m.ArTransactionCodesModule)
          }
        ]
      }, {
        path: 'chargebacks',
        children: [
          {
            path: '',
            loadChildren: () => import('./configuration/codes/chargebacks/chargebacks.module').then(m => m.ChargebacksModule),
            data: { title: 'Chargebacks', claimProgram: 'CodesChargeBacks', claimVerb: 'View' },
            canActivate: [AuthGuard]
          }, {
            path: 'add',
            loadChildren: () => import('./configuration/codes/chargebacks/edit-chargebacks/edit-chargebacks.module').then(m => m.EditChargebacksModule),
            data: { title: 'Add Chargebacks', claimProgram: 'CodesChargeBacks', claimVerb: 'Add' },
            canActivate: [AuthGuard]
          }, {
            path: ':id',
            loadChildren: () => import('./configuration/codes/chargebacks/edit-chargebacks/edit-chargebacks.module').then(m => m.EditChargebacksModule),
            data: { title: 'Edit Chargebacks', claimProgram: 'CodesChargeBacks', claimVerb: 'Edit' },
            canActivate: [AuthGuard]
          }
        ]
      }, {
        path: 'model',
        children: [
          {
            path: 'cost-codes',
            loadChildren: () => import('./configuration/codes/model/model-cost-codes/model-cost-codes.module').then(m => m.ModelCostCodesModule)
          }, {
            path: 'discontinued-codes',
            loadChildren: () => import('./configuration/codes/model/model-discontinued-codes/model-discontinued-codes.module').then(m => m.ModelDiscontinuedCodesModule)
          }, {
            path: 'price-codes',
            loadChildren: () => import('./configuration/codes/model/model-price-codes/model-price-codes.module').then(m => m.ModelPriceCodesModule)
          }
        ]
      }, {
        path: 'opportunity',
        children: [
          {
            path: '',
            loadChildren: () => import('./configuration/codes/opportunity/opportunity.module').then(m => m.OpportunityModule),
            data: { title: 'Opportunity', claimProgram: 'CodesOpportunities', claimVerb: 'View' },
            canActivate: [AuthGuard]
          }, {
            path: 'add',
            loadChildren: () => import('./configuration/codes/opportunity/edit-opportunity/edit-opportunity.module').then(m => m.EditOpportunityModule),
            data: { title: 'Add Opportunity', claimProgram: 'CodesOpportunities', claimVerb: 'Add' },
            canActivate: [AuthGuard]
          }, {
            path: ':id',
            loadChildren: () => import('./configuration/codes/opportunity/edit-opportunity/edit-opportunity.module').then(m => m.EditOpportunityModule),
            data: { title: 'Edit Opportunity', claimProgram: 'CodesOpportunities', claimVerb: 'Edit' },
            canActivate: [AuthGuard]
          }
        ]
      }, {
        path: 'opportunity-result',
        children: [
          {
            path: '',
            loadChildren: () => import('./configuration/codes/opportunity-result/opportunity-result.module').then(m => m.OpportunityResultModule),
            data: { title: 'Opportunity Result', claimProgram: 'CodesOpportunityResult', claimVerb: 'View' },
            canActivate: [AuthGuard]
          }, {
            path: 'add',
            loadChildren: () => import('./configuration/codes/opportunity-result/edit-opportunity-result/edit-opportunity-result.module').then(m => m.EditOpportunityResultModule),
            data: { title: 'Add Opportunity Result', claimProgram: 'CodesOpportunityResult', claimVerb: 'Add' },
            canActivate: [AuthGuard]
          }, {
            path: ':id',
            loadChildren: () => import('./configuration/codes/opportunity-result/edit-opportunity-result/edit-opportunity-result.module').then(m => m.EditOpportunityResultModule),
            data: { title: 'Edit Opportunity Result', claimProgram: 'CodesOpportunityResult', claimVerb: 'Edit' },
            canActivate: [AuthGuard]
          }
        ]
      }, {
        path: 'payable-hold',
        children: [
          {
            path: '',
            loadChildren: () => import('./configuration/codes/payable-hold/payable-hold.module').then(m => m.PayableHoldModule),
            data: { title: 'Payable Hold', claimProgram: 'CodesPayableHold', claimVerb: 'View' },
            canActivate: [AuthGuard]
          }, {
            path: 'add',
            loadChildren: () => import('./configuration/codes/payable-hold/edit-payable-hold/edit-payable-hold.module').then(m => m.EditPayableHoldModule),
            data: { title: 'Add Payable Hold', claimProgram: 'CodesPayableHold', claimVerb: 'Add' },
            canActivate: [AuthGuard]
          }, {
            path: ':id',
            loadChildren: () => import('./configuration/codes/payable-hold/edit-payable-hold/edit-payable-hold.module').then(m => m.EditPayableHoldModule),
            data: { title: 'Edit Payable Hold', claimProgram: 'CodesPayableHold', claimVerb: 'Edit' },
            canActivate: [AuthGuard]
          }
        ]
      }, {
        path: 'purchase-order',
        children: [
          {
            path: '',
            loadChildren: () => import('./configuration/codes/purchase-order/purchase-order.module').then(m => m.PurchaseOrderModule),
            data: { title: 'Purchase Order', claimProgram: 'CodesPurchaseOrder', claimVerb: 'View' },
            canActivate: [AuthGuard]
          }, {
            path: 'add',
            loadChildren: () => import('./configuration/codes/purchase-order/edit-purchase-order/edit-purchase-order.module').then(m => m.EditPurchaseOrderModule),
            data: { title: 'Add Purchase Order', claimProgram: 'CodesPurchaseOrder', claimVerb: 'Add' },
            canActivate: [AuthGuard]
          }, {
            path: ':id',
            loadChildren: () => import('./configuration/codes/purchase-order/edit-purchase-order/edit-purchase-order.module').then(m => m.EditPurchaseOrderModule),
            data: { title: 'Edit Purchase Order', claimProgram: 'CodesPurchaseOrder', claimVerb: 'Edit' },
            canActivate: [AuthGuard]
          }
        ]
      }, {
        path: 'purchase-order-discounts',
        children: [
          {
            path: '',
            loadChildren: () => import('./configuration/codes/purchase-order-discounts/purchase-order-discounts.module').then(m => m.PurchaseOrderDiscountsModule),
            data: { title: 'Purchase Order Discounts', claimProgram: 'CodesPurchaseOrderDiscounts', claimVerb: 'View' },
            canActivate: [AuthGuard]
          }, {
            path: 'add',
            loadChildren: () => import('./configuration/codes/purchase-order-discounts/edit-purchase-order-discounts/edit-purchase-order-discounts.module').then(m => m.EditPurchaseOrderDiscountsModule),
            data: { title: 'Add Order Discount', claimProgram: 'CodesPurchaseOrderDiscounts', claimVerb: 'Add' },
            canActivate: [AuthGuard]
          }, {
            path: ':id',
            loadChildren: () => import('./configuration/codes/purchase-order-discounts/edit-purchase-order-discounts/edit-purchase-order-discounts.module').then(m => m.EditPurchaseOrderDiscountsModule),
            data: { title: 'Edit Order Discount', claimProgram: 'CodesPurchaseOrderDiscounts', claimVerb: 'Edit' },
            canActivate: [AuthGuard]
          }
        ]
      }, {
        path: 'return-reasons',
        children: [
          {
            path: '',
            loadChildren: () => import('./configuration/codes/return-reasons/return-reasons.module').then(m => m.ReturnReasonsModule),
            data: { title: 'Return Reasons', claimProgram: 'CodesReturnReasons', claimVerb: 'View' },
            canActivate: [AuthGuard]
          }, {
            path: 'add',
            loadChildren: () => import('./configuration/codes/return-reasons/edit-return-reasons/edit-return-reasons.module').then(m => m.EditReturnReasonsModule),
            data: { title: 'Add Return Reason', claimProgram: 'CodesReturnReasons', claimVerb: 'Add' },
            canActivate: [AuthGuard]
          }, {
            path: ':id',
            loadChildren: () => import('./configuration/codes/return-reasons/edit-return-reasons/edit-return-reasons.module').then(m => m.EditReturnReasonsModule),
            data: { title: 'Edit Return Reason', claimProgram: 'CodesReturnReasons', claimVerb: 'Edit' },
            canActivate: [AuthGuard]
          },
        ]
      }, {
        path: 'sales-order-discount-codes',
        children: [
          {
            path: '',
            loadChildren: () => import('./configuration/codes/sales-order-discount-codes/sales-order-discount-codes.module').then(m => m.SalesOrderDiscountCodesModule),
          }
        ]
      },{
        path: 'sales-order-referral-type-codes',
        children: [
          {
            path: '',
            loadChildren: () => import('./configuration/codes/sales-order-referral-type-codes/sales-order-referral-type-codes.module').then(m => m.SalesOrderReferralTypeCodesModule),
          }
        ]
      }, {
        path: 'sales-persons',
        children: [
          {
            path: '',
            loadChildren: () => import('./configuration/codes/sales-persons/sales-persons.module').then(m => m.SalesPersonsModule),
            data: { title: 'Sales Persons', claimProgram: 'CodesSalesPersons', claimVerb: 'View' },
            canActivate: [AuthGuard]
          }, {
            path: 'add',
            loadChildren: () => import('./configuration/codes/sales-persons/edit-sales-persons/edit-sales-persons.module').then(m => m.EditSalesPersonsModule),
            data: { title: 'Add Sales Person', claimProgram: 'CodesSalesPersons', claimVerb: 'Add' },
            canActivate: [AuthGuard]
          }, {
            path: ':id',
            loadChildren: () => import('./configuration/codes/sales-persons/edit-sales-persons/edit-sales-persons.module').then(m => m.EditSalesPersonsModule),
            data: { title: 'Edit Sales Person', claimProgram: 'CodesSalesPersons', claimVerb: 'Edit' },
            canActivate: [AuthGuard]
          }
        ]
      }, {
        path: 'service-order-codes',
        loadChildren: () => import('./configuration/codes/service-order-codes/service-order-codes.module').then(m => m.ServiceOrderCodesModule)
      }, {
        path: 'service-order-reason-codes',
        loadChildren: () => import('./configuration/codes/service-order-reason-codes/service-order-reason-codes.module').then(m => m.ServiceOrderReasonCodesModule)
      }]
  }, {
    path: 'commissions',
    children: [
      {
        path: '',
        loadChildren: () => import('./configuration/commissions/commissions.module').then(m => m.CommissionsModule),
        data: {title: 'Commissions', claimProgram: 'Commissions', claimVerb: 'View'},
        canActivate: [AuthGuard]
      }
    ]
  }, {
    path: 'companies',
    children: [
      {
        path: '',
        loadChildren: () => import('./configuration/companies/companies.module').then(m => m.CompaniesModule)
      }
    ]
  }, {
    path: 'model-config',
    loadChildren: () => import('./configuration/model-configs/model-configs.module').then(m => m.ModelConfigsModule),
    data: {title: 'Model Configuration', claimProgram: 'ModelConfig', claimVerb: 'View'},
    canActivate: [AuthGuard]
  }, {
    path: 'zip-codes',
    loadChildren: () => import('./configuration/postal-codes/postal-codes.module').then(m => m.PostalCodesModule),
    data: {title: 'Postal Codes', claimProgram: 'PostalCodes', claimVerb: 'View'},
    canActivate: [AuthGuard]
  }, {
    path: 'role-groups',
    children: [
      {
        path: '',
        loadChildren: () => import('./configuration/role-groups/role-groups.module').then(m => m.RoleGroupsModule),
        data: { title: 'Roles', claimProgram: 'Roles', claimVerb: 'View' },
        canActivate: [AuthGuard]
      }, {
        path: 'add',
        loadChildren: () => import('./configuration/role-groups/role-group-edit/role-group-edit.module').then(m => m.RoleGroupEditModule),
        data: { title: 'Add Role Group', claimProgram: 'Roles', claimVerb: 'Add' },
        canActivate: [AuthGuard]
      }, {
        path: ':id',
        loadChildren: () => import('./configuration/role-groups/role-group-edit/role-group-edit.module').then(m => m.RoleGroupEditModule),
        data: { title: 'Edit Role Group', claimProgram: 'Roles', claimVerb: ['Edit', 'View'] },
        canActivate: [AuthGuard]
      }
    ]
  }, {
    path: 'sales-persons',
    loadChildren: () => import('./configuration/sales-persons/sales-persons.module').then(m => m.SalesPersonsModule),
    data: {title: 'Sales Persons', claimProgram: 'Salespersons', claimVerb: 'View' },
    canActivate: [AuthGuard]
  }, {
    path: 'stores',
    loadChildren: () => import('./configuration/stores/stores.module').then(m => m.StoresModule),
    data: {title: 'Stores', claimProgram: 'Stores', claimVerb: 'View'},
    canActivate: [AuthGuard]
  },
  {
    path: 'taxes',
    children: [
      {
        path: '',
        loadChildren: () => import('./configuration/tax-maintenance/tax-maintenance.module').then(m => m.TaxMaintenanceModule),
        data: {title: 'Taxes', claimProgram: 'TaxMaintenance', claimVerb: 'View'},
        canActivate: [AuthGuard]
      }
    ]
  }, {
    path: 'users',
    children: [
      {
        path: '',
        loadChildren: () => import('./configuration/users/users.module').then(m => m.UsersModule),
        data: { title: 'Users', claimProgram: 'Users', claimVerb: 'View' },
        canActivate: [AuthGuard]
      }, {
        path: 'add',
        loadChildren: () => import('./configuration/users/users-edit/users-edit.module').then(m => m.UsersEditModule),
        data: { title: 'Add User', claimProgram: 'Users', claimVerb: 'Add' },
        canActivate: [AuthGuard]
      }, {
        path: ':id',
        loadChildren: () => import('./configuration/users/users-edit/users-edit.module').then(m => m.UsersEditModule),
        data: { title: 'Add User', claimProgram: 'Users', claimVerb: ['Edit', 'View'] },
        canActivate: [AuthGuard]
      }
    ]
  }]
}, {
  path: 'admin',
  children: [
    {
      path: 'logged-in-users',
      loadChildren: () => import('./admin/logged-in-users/logged-in-users.module').then(m => m.LoggedInUsersModule)
    }, {
      path: 'print-documents',
      loadChildren: () => import('./admin/print-documents/print-documents.module').then(m => m.PrintDocumentsModule)
    }, {
      path: 'system-parameters',
      loadChildren: () => import('./admin/system-parameters/system-parameters.module').then(m => m.SystemParametersModule)
    }, {
      path: 'dealer-programs',
      loadChildren: () => import('./admin/dealer-programs/dealer-programs.module').then(m => m.DealerProgramsModule)
    }, {
      path: 'external-integrations',
      loadChildren: () => import('./admin/external-integrations/external-integrations.module').then(m => m.ExternalIntegrationsModule)
    }
  ]
}, {
  path: '**',
  loadChildren: () => import('./not-found/not-found.module').then(m => m.NotFoundModule),
  data: {title: 'Not Found'},
  canActivate: [AuthGuard]
}];

@NgModule({
  imports: [
    RouterModule.forRoot(
      routes,
      {
        relativeLinkResolution: 'legacy',
        enableTracing: false
      }
    )
  ],
  exports: [RouterModule],
  providers: [
    AuthGuard,
    CanDeactivateGuard
  ]
})

export class AppRoutingModule { }
