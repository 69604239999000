import { Component, OnInit } from '@angular/core';
import { Router, ActivationEnd } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { LocalStorageService } from './services/localstorage.service';
import { AuthService } from './services/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  showShell = false;
  menuOpen = true;

  pageTitle = 'PointCentric';

  toastMessage = null;

  windowWidth: number;

  constructor(
    private _authSvc: AuthService,
    private _lsSvc: LocalStorageService,
    private _router: Router,
    private _title: Title
  ) {  }

  /**
   * Component init
   */
  ngOnInit() {
    this.windowWidth = window.innerWidth;
    const menuOpen = this._lsSvc.getItem('menuOpen');

    if (menuOpen === null || menuOpen === false) { 
         this.menuOpen = false;
    }

    // if user is logged in but has not claims, trigger logout
    const claims = this._lsSvc.getItem('claims');  
    if (this._authSvc.isLoggedIn() && !claims) {
      this._authSvc.logout(true);
    }

  }

  /**
   * Menu state change event from header
   * Open/closes the side menu
   * @param {boolean} open
   */
  public onMenuStateChange(): void {
    this.menuOpen = this.menuOpen ? false : true;
    this._lsSvc.setItem('menuOpen', this.menuOpen);
  }

  /**
   * Check page width to see if menu should be closed
   */
  private checkWidth(): void {

    if (window.innerWidth <= 992) {
      this.menuOpen = false;
    }

  }

  /**
   * Window resize event handler
   */
  onResize(): void {

    // Only check width (for closing menu) if it has changed
    let windowWidth = window.innerWidth;
    if (windowWidth !== this.windowWidth) {
      this.windowWidth = windowWidth;
      this.checkWidth();
    }
  }

  /**
   *  Route activate event handler
   */
  public activate(_e: any): void {

    this._router.events.subscribe(data => {
      if (data instanceof ActivationEnd) {
        if (data.snapshot && data.snapshot.data && data.snapshot.data.title && this.pageTitle !== data.snapshot.data.title) {
          this.pageTitle = data.snapshot.data.title;
          this._title.setTitle(`${data.snapshot.data.title} | PointCentric`);
        }
      }
    });

    // toggle header and side navigation
    this.showShell = (this._router.url === '/' || this._router.url.startsWith('/?')) ? false : true;

    this.checkWidth();
  }
}
